<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item
            class="pointer"
            :to="{ path: '/streetGridManage' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('streetGridManage')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="streetGridManage"
                  ref="streetGridManage"
                  label-width="150px"
                  class="streetGridManage"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="后台登录账号" prop="loginName">
                        <el-input v-model="streetGridManage.loginName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="手机号" prop="phone">
                        <el-input v-model="streetGridManage.phone"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="人员类型" prop="type">
                        <el-select
                          v-model="streetGridManage.type"
                          placeholder="请选择人员类型"
                          @change="dangerChange"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="省" prop="streetCode">
                        <el-select
                          v-model="streetGridManage.provinceCode"
                          @change="changeProvince"
                          placeholder="请选择省"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in provinceList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="市" prop="cityCode">
                        <el-select
                          v-model="streetGridManage.cityCode"
                          @change="changeCity"
                          placeholder="请选择城市"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in cityList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="区县" prop="districtCode">
                        <el-select
                          v-model="streetGridManage.districtCode"
                          @change="changeDistrict"
                          placeholder="请选择区"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in districtList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="街道名称" prop="streetCode">
                        <el-select
                          v-model="streetGridManage.streetCode"
                          @change="changeStreet"
                          placeholder="请选择街道"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in streetList"
                            :key="item.streetCode"
                            :label="item.streetName"
                            :value="item.streetCode"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="网格" prop="">
                        <el-select v-model="streetGridManage.gridCodes" multiple placeholder="请选择网格">
                          <el-option v-for="item in gridList" :key="item.id" :label="item.gridName"
                            :value="item.gridCode">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      options: [
        { label: "网格长", value: "1" },
        { label: "管理员", value: "2" },
        { label: "警务员", value: "3" },
        { label: "监督员_协管员", value: "4" },
      ],
      streetGridManage: {
        streetCode: "",
        streetName: "",
        phone: "",
        creator: localStorage.getItem("login_name"),
        creatorCode: localStorage.getItem("userCode"),
        type: "",
        provinceCode: "",
        cityCode: "",
        districtCode: "",
        gridCodes:[], 
        loginName:"",
      },
      provinceList: [], //省列表
      cityList: [], //市列表
      districtList: [], //区县列表
      streetList: [], //  街道列表
      gridList:[],    //网格列表
    };
  },
  methods: {
    dangerChange(e) {
      this.streetGridManage.type = e;
    },

    goIndex() {
      this.$router.push({
        path: "/streetGridManage",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      var that = this;
      that.$http
        .post("/streetGridManage/save", that.streetGridManage)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.$router.go(-1);
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true,
          });
        });
    },
    loadBaseInfo() {
      let that = this;
      if(this.id != undefined && this.id != ""){
        that.$http
        .post("/streetGridManage/queryOne", { id: this.id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.streetGridManage = response.data.data;
            if (that.streetGridManage.provinceCode) {
              that.getCityList(that.streetGridManage.provinceCode);
            }
            if (that.streetGridManage.cityCode) {
              that.getDistrictList(that.streetGridManage.cityCode);
            }
            if (that.streetGridManage.districtCode) {
              that.getStreetList(that.streetGridManage.districtCode);
            }
            if(that.streetGridManage.streetCode) {
                that.getGridList(that.streetGridManage.streetCode);
              }
          }
        });
      }
      
    },
    changeProvince(e) {
      this.handleClearCode(1);
      this.getCityList(e);
    },
    changeCity(e) {
      this.handleClearCode(2);
      this.getDistrictList(e);
    },
    changeDistrict(e) {
      this.handleClearCode(3);
      this.getStreetList(e);
    },
    changeStreet(e) {
      this.handleClearCode(4);
      this.streetGridManage.streetName = this.streetList.find((item) => {
        return item.streetCode === e;
      }).streetName;
      this.getGridList(e);
    },
    handleClearCode(level) {
      //重新选择时，清空级联列表
      switch (level) {
        case 1:
          this.streetGridManage.cityCode = "";
          this.streetGridManage.districtCode = "";
          this.streetGridManage.streetCode = "";
          this.streetGridManage.gridCodes = [];
          break;
        case 2:
          this.streetGridManage.districtCode = "";
          this.streetGridManage.streetCode = "";
          this.streetGridManage.gridCodes = [];
          break;
        case 3:
          this.streetGridManage.streetCode = "";
          this.streetGridManage.gridCodes = [];
          break;
        case 4:
          this.streetGridManage.gridCodes = [];
          break;
        default:
          break;
      }
    },
    //获取省
    getProvinceList() {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.provinceList = response.data.data;
            var id = response.data.data[0].id;
            that.getCityList(id);
          }
        });
    },
    //获取市
    getCityList(id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.cityList = response.data.data;
            var e = response.data.data[0].id;
            that.getDistrictList(e);
          }
        });
    },

    //获取区
    getDistrictList(id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.districtList = response.data.data;
            var e = response.data.data[0].id;
            that.getStreetList(e);
          }
        });
    },
    // 查询所有街道
    getStreetList(id) {
      let that = this;
      that.$http
        .post("/area/queryStreetList", {
          id,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.streetList = response.data.data.queryStreetList;
          }
        });
    },
    //查询网格
    getGridList (streetCode) {
      let that = this;
      that.$http
        .post("/grid/queryListGroupBy", {
          streetCode: streetCode,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.gridList = response.data.data;
          }
        });
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getProvinceList();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
